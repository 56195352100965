import React, { useState, useRef, useEffect } from "react";
import {
  ArrowUp,
  BlackMic,
  BlackMicActive,
  Mic,
  MicActive,
} from "../../assets";
import { useTheme } from "../../context/ThemeProvider";
import PromptStore from "../../store/promptStore";
import { FileStore } from "../../store/fileUploadStore";
import { DeleteUploadedFile } from "../../utils/firestoreFunctions/deleteFileFromDb";
import UserAuthStore from "../../store/userStore";
import { handleFileUpload } from "../../utils/fileUploadHandler";
import StyleDropdown from "../Dropdowns/StyleDropdown";
import { updateRecaching } from "../../utils/firestoreFunctions/updateRecaching";
import AttachDropdown from "../Dropdowns/AttachDropdown";
import GlobalSearch from "../GlobalSearchIcon/iconGlobal";
import FileUploadItem from "./FileUploadItem";
import "../../assets/css/prompt.css";
import { toast } from "react-toastify";
import { useDragAndDrop } from "../../hooks/useDragAndDrop";
import {
  REGEX_PROMPT_NEWLINE,
  REGEX_TEXT_SPLIT,
} from "../../constants/regexValues";
import ChatStore from "../../store/chatStore";
import addChat from "../../utils/firestoreFunctions/addChat";
import { FirebaseUpload2 } from "../../utils/firestoreFunctions/firebaseUpload";
import { FaArrowDown } from "react-icons/fa6";
import ScrollToBottomStore from "../../store/ScrollToBottomStore";
import { deleteChat } from "../../utils/firestoreFunctions/deleteChat";

const ScrollToBottomBtn = () => {
  const showScrollButton = ScrollToBottomStore(
    (state) => state.showScrollButton
  );
  const { theme } = useTheme();
  const scrollToBottom = (smooth = true) => {
    const scrollRef = document.querySelector("#chat-messages");
    if (scrollRef) {
      scrollRef.scrollTo({
        top: scrollRef.scrollHeight,
        behavior: smooth ? "smooth" : "instant",
      });
    }
  };
  if (showScrollButton)
    return (
      <button
        onClick={() => scrollToBottom(true)}
        className={`absolute -top-12 left-1/2 transform -translate-x-1/2 translate-y-1/2 
             border-[0.5px] opacity-100 h-[26px] w-[26px] flex justify-center items-center rounded-full shadow-md transition z-50 ${
               theme === "dark"
                 ? "bg-[#30303D] border-[#6D808D]"
                 : "bg-white border-[#D4D6D7]"
             }`}
      >
        <FaArrowDown
          size={12}
          className={`${theme === "dark" ? "text-white" : "text-[#0d3148]"}`}
        />
      </button>
    );
};

const Prompt = ({
  send,
  chat_id,
  expanded,
  setExpanded,
  selectedStyle,
  setSelectedStyle,
  generateStatus,
  setGenerateStatus,
  messageLength = 0,
}) => {
  const { theme } = useTheme();
  const prompt = PromptStore((state) => state.prompt);
  const { setPrompt } = PromptStore.getState();
  const promptDisabled = PromptStore((state) => state.promptDisabled);
  const setPromptDisable = PromptStore((state) => state.setPromptDisable);

  const [text, setText] = useState("");
  const [micActive, setMicActive] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const recognitionRef = useRef(null);

  const { files } = FileStore();
  const { setIsDragging } = FileStore();
  const setFiles = FileStore((state) => state.setFiles);
  const { user, orgId } = UserAuthStore.getState();
  const [uploadingFiles, setUploadingFiles] = useState({});
  const { handleDrop } = useDragAndDrop();
  const { isDragging } = FileStore();
  const { tempChatId, setTempChatId } = ChatStore();

  // Function to handle file removal
  const removeFile = async (file) => {
    updateRecaching(chat_id, true);
    // Clean up upload state
    setUploadingFiles((prev) => {
      const newState = { ...prev };
      delete newState[file.name];
      return newState;
    });
    await DeleteUploadedFile(file?.file__path, chat_id, file);
  };

  // Initialize speech recognition
  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      recognitionRef.current = new window.webkitSpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.lang = "en-US";

      let silenceTimeout;

      recognitionRef.current.onstart = () => {
        setIsListening(true);
        setMicActive(true);
      };

      recognitionRef.current.onresult = (event) => {
        let finalTranscript = "";

        for (let i = event.resultIndex; i < event.results.length; i++) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
          }
        }

        if (finalTranscript) {
          setPrompt(finalTranscript);
        }

        // Reset silence timeout on every new result
        if (silenceTimeout) clearTimeout(silenceTimeout);
        silenceTimeout = setTimeout(() => {
          recognitionRef.current?.stop();
        }, 2000);
      };

      recognitionRef.current.onend = () => {
        setIsListening(false);
        setMicActive(false);
      };

      recognitionRef.current.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
        setIsListening(false);
        setMicActive(false);
      };
    }

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.abort();
      }
    };
  }, []);

  const requestMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop()); // Stop the stream after getting permission
      return true;
    } catch (error) {
      console.error("Error requesting microphone permission:", error);
      return false;
    }
  };

  const toggleMic = async () => {
    if (prompt) {
      return;
    }

    if (!("webkitSpeechRecognition" in window)) {
      toast.warning("Speech recognition is not supported in this browser.");
      return;
    }

    try {
      if (!isListening) {
        const hasPermission = await requestMicrophonePermission();
        if (!hasPermission) {
          toast.warning(
            "Microphone permission is required for speech recognition."
          );
          return;
        }
        recognitionRef.current?.start();
      } else {
        recognitionRef.current?.stop();
      }
    } catch (error) {
      console.error("Error toggling microphone:", error);
      setIsListening(false);
      setMicActive(false);
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "24px";
      const newHeight = Math.min(textarea.scrollHeight, 5 * 20);
      textarea.style.height = `${Math.max(24, newHeight)}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
    const normalizedPrompt = prompt.replace(REGEX_PROMPT_NEWLINE, "\n");
    if (normalizedPrompt !== prompt) {
      setPrompt(normalizedPrompt);
    }
  }, [prompt]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [text]);

  const trimToWordLimit = (text, limit = 3000) => {
    const words = text
      .split(REGEX_TEXT_SPLIT)
      .filter((word) => word.length > 0);
    return words.length > limit ? words.slice(0, limit).join(" ") : text;
  };

  const handleInputChange = (e) => {
    const inputText = trimToWordLimit(e.target.value);
    if (inputText !== prompt) {
      setPrompt(inputText);
    }
  };

  const realSend = async () => {
    if (prompt.trim() !== "" && !promptDisabled && !generateStatus) {
      setGenerateStatus(true);
      setPromptDisable(true);
  
      if (isListening) {
        recognitionRef.current?.stop();
        setIsListening(false);
        setMicActive(false);
      }
  
      let savedChatId = null;
  
      try {
        // Save chat only if it's temporary
        if (tempChatId) {
          savedChatId = await addChat(); // Save to DB
          setTempChatId(null); // Reset temp ID
          ChatStore.setState((prevState) => ({
            sideChat: prevState.sideChat.map((chat) =>
              chat.uid === tempChatId
                ? { ...chat, uid: savedChatId, temp: false }
                : chat
            ),
          }));
        }
  
        if (messageLength === 0) {
          await FirebaseUpload2(chat_id, user, orgId);
        }
  
        await send(); // Send the prompt
  
      } catch (error) {
        console.log("Error in sending prompt:", error);
  
        // If chat was saved but sending failed, delete it from DB
        if (savedChatId) {
          await deleteChat(savedChatId);
          console.log(`Chat ${savedChatId} deleted due to send failure.`);
          ChatStore.setState((prevState) => ({
            sideChat: prevState.sideChat.filter((chat) => chat.uid !== savedChatId),
          }));
        }
        
      } finally {
        setGenerateStatus(false);
        setPromptDisable(false);
      }
  
      // Update chat order in UI
      ChatStore.setState((prevState) => {
        const updatedSideChat = prevState.sideChat.map((chatItem, index) => {
          if (chatItem.uid === chat_id) {
            return {
              ...chatItem,
              pinned_date: {
                seconds: Math.floor(Date.now() / 1000),
                nanoseconds: (Date.now() % 1000) * 1e6,
              },
              updated_at: new Date(),
              originalIndex: index,
            };
          }
          return chatItem;
        });
  
        // Sort pinned and unpinned chats
        const pinnedChats = updatedSideChat
          .filter((chat) => chat.pinned)
          .sort((a, b) => {
            const dateA =
              a.pinned_date.seconds * 1000 + a.pinned_date.nanoseconds / 1e6;
            const dateB =
              b.pinned_date.seconds * 1000 + b.pinned_date.nanoseconds / 1e6;
            return dateB - dateA;
          });
  
        const unpinnedChats = updatedSideChat
          .filter((chat) => !chat.pinned)
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
  
        return { sideChat: [...pinnedChats, ...unpinnedChats] };
      });
  
      setPrompt("");
      if (textareaRef.current) {
        textareaRef.current.style.height = "24px";
      }
    }
  };
  

  const handleSendingPrompt = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      realSend();
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  return (
    <div
      className="chat-input-container max-w-5xl mx-auto my-2 w-full px-6 relative"
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver} // Prevent default to allow drop
      onDragLeave={handleDragLeave}
      onDrop={(e) =>
        handleDrop(
          e,
          fileInputRef,
          handleFileUpload,
          chat_id,
          setFiles,
          files,
          user,
          orgId,
          messageLength
        )
      }
    >
      <ScrollToBottomBtn />
      <div
        className={`dark:bg-[#353C4D] bg-white rounded-2xl px-3 sm:px-4 py-2 sm:py-3`}
      >
        {/* Hidden file input */}
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={(e) =>
            handleFileUpload(
              e,
              chat_id,
              fileInputRef,
              setFiles,
              files,
              user,
              orgId,
              messageLength
            )
          }
          accept=".pdf,.csv,.docx,.txt,.xlsx,.doc"
          id="chatFileInput"
        />
        <div className="relative my-2">
          <div className="relative">
            <textarea
              placeholder={
                isDragging ? "Drop here..." : "Enter your prompt here..."
              }
              className="w-full bg-transparent dark:text-white text-[#0D3148] placeholder-[#9FABCA] opacity-100 placeholder:italic px-3 pr-12 focus:outline-none text-[15px] resize-none overflow-y-auto leading-5"
              ref={textareaRef}
              value={prompt}
              onChange={handleInputChange}
              onKeyDown={(e) => handleSendingPrompt(e)}
              aria-label="Prompt input"
            />
            <div className="absolute right-0 top-[-8px] bottom-0 w-12 flex items-center justify-center my-2">
              <button
                onClick={
                  !promptDisabled || !generateStatus
                    ? prompt && !isListening
                      ? realSend
                      : toggleMic
                    : null
                }
                disabled={generateStatus}
                className={`transition-colors ${
                  promptDisabled
                    ? "opacity-50 cursor-not-allowed"
                    : prompt
                    ? "text-[#FAAD47] hover:text-amber-300"
                    : isListening
                    ? "text-red-400 animate-pulse"
                    : "text-emerald-400 hover:text-emerald-300"
                }`}
                aria-label={text ? "Send message" : "Voice input"}
              >
                {!isListening && prompt ? (
                  <div
                    className={`bg-[#FAAD47] opacity-100 rounded-full w-9 h-9 flex items-center justify-center ${
                      generateStatus
                        ? "opacity-50 cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                    disabled={generateStatus}
                  >
                    <ArrowUp className="text-white w-[11px] h-4" />
                  </div>
                ) : (
                  <>
                    {micActive ? (
                      theme === "dark" ? (
                        <MicActive size={20} />
                      ) : (
                        <BlackMicActive size={20} />
                      )
                    ) : theme === "dark" ? (
                      <Mic size={20} />
                    ) : (
                      <BlackMic size={20} />
                    )}
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap md:flex-nowrap items-center gap-2 border-t border-[#CCCCCC] dark:border-[#454E5A] pt-2">
          <div className="flex gap-2 flex-shrink-0">
            {/* Attach Dropdown */}
            <AttachDropdown fileInputRef={fileInputRef} />

            {/* Respond Style Dropdown */}
            <StyleDropdown
              selectedStyle={selectedStyle}
              setSelectedStyle={setSelectedStyle}
            />

            {/* globalSearch Icon */}
            <GlobalSearch
              expanded={expanded}
              setExpanded={setExpanded}
              disabled={files.length > 0}
            />

            <div
              className={`${
                files?.length
                  ? "border-r border-[#CCCCCC] dark:border-[#454E5A]"
                  : ""
              }`}
            ></div>
          </div>

          {/* Files display section */}
          <div className="flex-grow overflow-x-auto hide-scrollbar">
            <div className="flex gap-2 items-center">
              {files?.length
                ? files.map((file, index) => (
                    <FileUploadItem
                      key={index}
                      file={file}
                      onRemove={removeFile}
                      progress={file?.progress || 0}
                      isUploading={uploadingFiles[file.name]}
                    />
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prompt;
