import { apiUrl } from "../constants/const";
import addMessages from "../utils/firestoreFunctions/addMessages";

const regenerate = async ({
  id,
  messages,
  setMessages,
  setIsLatestMessageLoading,
  modeRef,
  chat_id,
  orgId,
  user,
  updateChatTitle,
  files,
  modelSelected,
  globalSearchDropdown,
  setRegenerateIndex,
}) => {
  try {
    setIsLatestMessageLoading(true);
    let message_data = null;

    for (const message of messages) {
      if (message.id === id) {
        message_data = message;
      }
    }

    setMessages((prevMessages) =>
      prevMessages.map((message) => {
        if (message.id === id) {
          return {
            ...message,
            answers: [
              ...message.answers,
              {
                content: "",
                timestamp: {
                  seconds: 1736245799,
                  nanoseconds: 84000000,
                },
                source: "None",
                active: true,
                role: "model",
              },
            ],
            currentIndex: message.answers.length,
          };
        }
        return message;
      })
    );
    const response = await fetch(apiUrl + "/generate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: modeRef,
        prompt: message_data.content,
        chat_uid: chat_id,
        file_url: message_data.source || [],
        org_id: orgId,
        uid: user?.uid,
        regenerate: true,
        style: null,
        cache_id: null,
        model_id: modelSelected,
        recaching: false,
        google_search: globalSearchDropdown,
        file_data: "",
        prompt_id: id,
        new_prompt: "",
      }),
    });

    if (!response.body) {
      throw new Error("Readable stream not supported in response.");
    }

    if (messages.length === 0) {
      updateChatTitle(chat_id, message_data.content);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let answerCont = "";

    for (let done = false; !done; ) {
      const { value, done: readerDone } = await reader.read();
      done = readerDone;

      if (value) {
        const chunk = decoder.decode(value, { stream: true });

        if (chunk === "</END>") {
          await addMessages(
            orgId,
            chat_id,
            message_data.content,
            answerCont,
            user?.uid,
            id,
            "None",
            files?.map((file) => file.downloadURL),
            modelSelected,
          );
        } else {
          answerCont += chunk;
          setMessages((prevMessages) =>
            prevMessages.map((message) => {
              if (message.id === id) {
                return {
                  ...message,
                  answers: message.answers.map((answer, index) => {
                    if (index === message.answers.length - 1) {
                      return {
                        ...answer,
                        content: answer.content + chunk,
                      };
                    }
                    return answer;
                  }),
                };
              }
              return message;
            })
          );
        }
      }
    }
  } catch (error) {
    console.error("Error fetching messages:", error);
  } finally {
    setRegenerateIndex(null);
    setIsLatestMessageLoading(false);
  }
};

export default regenerate;
