import { useRef } from "react";
import {
  BlackCross,
  CrossSvg,
  LightOrgSearch,
  LightPdf,
  LightPdfUp,
  Orgsearch,
  Pdf,
  PdfUpload,
} from "../../../assets";
import { FileStore } from "../../../store/fileUploadStore";
import { DeleteUploadedFile } from "../../../utils/firestoreFunctions/deleteFileFromDb";
import UserAuthStore from "../../../store/userStore";
import { handleFileUpload } from "../../../utils/fileUploadHandler";
import { updateRecaching } from "../../../utils/firestoreFunctions/updateRecaching";
import "../../../assets/css/prompt.css";
import { useDragAndDrop } from "../../../hooks/useDragAndDrop";

export const FileUploadZone = ({ theme, chat_id }) => {
  const files = FileStore((state) => state.files);
  const fileInputRef = useRef(null);
  const setFiles = FileStore((state) => state.setFiles);
  const { user, orgId } = UserAuthStore.getState();
  const { handleDrop } = useDragAndDrop();
  const { isDragging } = FileStore();
  const { setIsDragging } = FileStore();

  const formatFileSize = (bytes) => {
    const mb = (bytes / (1024 * 1024)).toFixed(1);
    return `${mb} MB`;
  };

  const removeFile = async (file) => {
    updateRecaching(chat_id, true);
    DeleteUploadedFile(file?.file__path, chat_id, file);
  };

  const handleOnChange = (e) => {
    handleFileUpload(e, chat_id, fileInputRef, setFiles, files, user, orgId);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const renderSingleFileView = (file) => (
    <div className="relative w-full p-6">
      <button
        onClick={() => removeFile(file, 0)}
        className="absolute right-2 top-2 dark:text-[#37E8C5] hover:opacity-80"
      >
        {theme === "dark" ? <CrossSvg /> : <BlackCross />}
      </button>
      <div className="flex flex-col items-center">
        <div className="text-center mb-2">
          {theme === "dark" ? (
            <Pdf className="h-12 w-12" />
          ) : (
            <LightPdfUp className="h-12 w-12" />
          )}
        </div>
        <p className="dark:text-white text-[#0D3148] text-sm mb-1">
          {file.name}
        </p>
        <p className="text-gray-400 text-xs mb-4">
          File Size: {formatFileSize(file.size)}
        </p>
        {file?.progress < 100 && (
          <div className="w-full max-w-md">
            <div className="w-full bg-[#2A2B32] rounded-full h-2">
              <div
                className="bg-[#FAAD47] h-full rounded-full transition-all duration-300"
                style={{ width: `${file?.progress || 0}%` }}
              />
            </div>
            <div className="text-gray-400 text-xs text-right mt-1">
              {file?.progress || 0}%
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const renderMultipleFilesView = () => (
    <div className="flex flex-row items-center overflow-x-auto scrollbar-hide gap-3">
      {files.map((file, index) => (
        <>
          <div key={index} className="relative w-52 p-2 first:ml-2">
            <button
              onClick={() => removeFile(file, index)}
              className="absolute right-1 top-[-10px] dark:text-[#37E8C5] text-xl hover:opacity-80"
            >
              {theme === "dark" ? <CrossSvg /> : <BlackCross />}
            </button>
            <div className="dark:text-white mb-1 mt-2 text-sm truncate">
              {file.name}
            </div>
            <div className="text-gray-400 text-xs">
              {formatFileSize(file.size)}
            </div>
            {file?.progress < 100 && (
              <>
                <div className="mt-2 w-full bg-[#2A2B32] rounded-full h-1.5">
                  <div
                    className="bg-[#FAAD47] h-full rounded-full transition-all duration-300"
                    style={{ width: `${file?.progress || 0}%` }}
                  />
                </div>
                <div className="text-gray-400 text-sm mt-1">
                  {file?.progress || 0}%
                </div>
              </>
            )}
          </div>
          {files.length > 1 && index !== files.length - 1 && (
            <span className="h-[70%] border-r border-[#CCCCCC] dark:border-[#454E5A]"></span>
          )}
        </>
      ))}
    </div>
  );

  return (
    <div
      className="relative flex rounded-2xl w-full overflow-hidden"
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver} // Prevent default to allow drop
      onDragLeave={handleDragLeave}
      onDrop={(e) =>
        handleDrop(
          e,
          fileInputRef,
          handleFileUpload,
          chat_id,
          setFiles,
          files,
          user,
          orgId
        )
      }
    >
      {/* Left Section */}
      <div className=" p-6 flex flex-col flex-shrink-0 items-center justify-center">
        <div className="text-[#37E8C5] mb-4">
          {theme === "dark" ? (
            <Orgsearch className="h-12 w-12" />
          ) : (
            <LightOrgSearch className="h-12 w-12" />
          )}
        </div>
        <p className="dark:text-white text-[#0D3148] opacity-100 text-base font-semibold text-center leading-tight whitespace-nowrap">
          Drop your document
          <br />
          to search within it.
        </p>
      </div>

      <div className="dark:bg-[#373745] bg-[#FFF0EB] flex flex-grow overflow-x-auto scrollbar-hide hide-scrollbar justify-center relative rounded-[20px] custom-border-light dark:custom-border-dark">
        {files?.length ? (
          files.length === 1 ? (
            renderSingleFileView(files[0])
          ) : (
            renderMultipleFilesView()
          )
        ) : (
          // Upload Zone
          <div className="flex p-2">
            <div
              className={`h-full dark:bg-[#373745] bg-[#FFF0EB] ${
                isDragging
                  ? "border-[#37E8C5] bg-[#2A2B32]"
                  : "border-[#9FABCA] bg-[#FFF0EB]"
              } rounded-2xl flex flex-col items-center justify-center`}
            >
              {theme === "dark" ? (
                <PdfUpload className="h-[75px] w-[98px] mb-4" />
              ) : (
                <LightPdf className="h-[75px] w-[98px] mb-4" />
              )}
              <div className="text-center flex flex-col">
                {isDragging ? (
                  <p className="dark:text-[#9FABCA] opacity-100 text-[#6D808D] text-lg font-semibold mb-1">
                    Drop here
                  </p>
                ) : (
                  <>
                    <div className="flex gap-1">
                      <p
                        className="dark:text-[#37E8C5] text-[#FF5B29] text-sm font-semibold hover:underline cursor-pointer mb-1"
                        onClick={() => fileInputRef.current?.click()}
                      >
                        Click to upload
                      </p>
                      <p className="dark:text-[#9FABCA] text-[#6D808D] text-sm mb-1">
                        or drag and drop
                      </p>
                    </div>
                    <p className="dark:text-[#CCD6F6] opacity-40 text-[#6D808D] text-xs">
                      Maximum file size 7 MB.
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        <input
          ref={fileInputRef}
          type="file"
          className="hidden"
          onChange={handleOnChange}
          accept=".pdf,.csv,.docx,.txt,.xlsx,.doc"
        />
      </div>
    </div>
  );
};

export default FileUploadZone;
