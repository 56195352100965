import React from "react";
import UIStore from "../../../store/uiStore";
import { FileUploadZone } from "./FileUploadZone";
import { useTheme } from "../../../context/ThemeProvider";
import UserAuthStore from "../../../store/userStore";
import Dark_Icon from "../../../assets/images/Dark_Icon.png";
import LightLogoIcon from "../../../assets/images/light_logo_icon.png";
import { useDragAndDrop } from "../../../hooks/useDragAndDrop";
import { FileStore } from "../../../store/fileUploadStore";
import { handleFileUpload } from "../../../utils/fileUploadHandler";

const DashSection = ({ chat_id, fileInputRef, isDragging }) => {
  const { modeRef } = UIStore();
  const { theme } = useTheme();
  const { user, orgId } = UserAuthStore();
  const files = FileStore((state) => state.files);
  const setFiles = FileStore((state) => state.setFiles);
  const { handleDrag, handleDragIn, handleDragOut, handleDrop } =
    useDragAndDrop();

    const handleOnDrop = (e) => {
      handleDrop(e, fileInputRef, handleFileUpload, chat_id, setFiles, files, user, orgId);
    };
  return (
    <div
      onDrop={handleOnDrop}
    >
      {/* Main container */}
      <div className="w-full px-4 sm:px-6">
        <div className="text-center mb-6 sm:mb-10 lg:mb-14 pt-6 sm:pt-8 lg:pt-12">
          {/* Mobile Logo - Better breakpoint control */}
          <div className="lg:hidden mb-6 flex justify-center">
            <img
              src={theme === "dark" ? Dark_Icon : LightLogoIcon}
              alt="Photon Logo"
              className="w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16"
            />
          </div>

          {/* Greeting */}
          <h1 className="opacity-100 text-xl sm:text-xl lg:text-[22px] mb-2 sm:mb-3 font-semibold greeting-txt-gradient">
            {user?.displayName ? `Hi, ${user?.displayName}` : "Hi,"}
          </h1>

          {/* Assistance text */}
          <h2 className="dark:text-white text-[#0D3148] opacity-100 text-lg lg:text-xl mb-3 font-bold whitespace-nowrap">
            Can I help you with anything?
          </h2>

          {/* Description */}
          <p className="dark:text-[#9FABCA] text-[#6D808D] opacity-100 text-[13px] xm:text-sm mx-auto mt-2">
            Photon is ready to assist you with anything you need, including
            <br className="hidden sm:block" />
            <span className="sm:hidden"> </span>
            queries answered and support for your regular work tasks.
          </p>
        </div>
      </div>
      {modeRef && modeRef === "global" ? (
        <>
          <div className="lg:flex sm:hidden xm:hidden xs:hidden xxs:hidden dark:bg-dropShadow bg-white rounded-2xl p-3 h-44 w-full min-w-4xl">
            <FileUploadZone
              theme={theme}
              chat_id={chat_id}
              fileInputRef={fileInputRef}
              isDragging={isDragging}
              handleFileUpload={handleFileUpload}
              setFiles={setFiles}
              files={files}
              user={user}
              orgId={orgId}
              handleDrag={handleDrag}
              handleDrop={handleDrop}
              handleDragIn={handleDragIn}
              handleDragOut={handleDragOut}
            />
          </div>
        </>
      ) : (
        <> </>
      )}
    </div>
  );
};

export default DashSection;
