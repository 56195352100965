import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ChatStore from "../../store/chatStore";
import { deleteChat } from "../../utils/firestoreFunctions/deleteChat";
import {
  pinChatAPI,
  updateChatTitle,
} from "../../utils/firestoreFunctions/updateChatTitle";
import FileUploadStore from "../../store/fileUploadStore";
import PromptStore from "../../store/promptStore";
import UIStore from "../../store/uiStore";
import { BlackDot, Dot, EditIcon } from "../../assets";
import { RiDeleteBin6Line } from "react-icons/ri";
import { LuPinOff } from "react-icons/lu";
import { MdOutlinePushPin, MdPushPin } from "react-icons/md";
import useGenerateApiSignal from "../../store/useGenerateApiSignal";

const ChatTitleDotMenu = ({ menuButtonRef, chat, handleDotClick, theme }) => {
  return (
    <button
      ref={menuButtonRef}
      className={`w-6 h-6 flex items-center justify-center rounded-full transition ${
        chat.pinned
          ? "dark:text-white"
          : "menu-button hidden dark:bg-[#3A3E4C] dark:text-white"
      }`}
      onClick={() => handleDotClick(chat.uid)}
    >
      {chat.pinned ? (
        <div className="pin-container">
          <MdPushPin className="w-5 h-5 pin" />
          {theme === "dark" ? (
            <Dot className="w-4 h-4 text-gray-500 pinned-dots" />
          ) : (
            <BlackDot className="w-4 h-4 text-gray-800 pinned-dots" />
          )}
        </div>
      ) : (
        <div className="">
          {theme === "dark" ? (
            <Dot className="w-4 h-4 text-gray-500" />
          ) : (
            <BlackDot className="w-4 h-4 text-gray-800" />
          )}
        </div>
      )}
    </button>
  );
};

const ChatTitle = ({ theme, chat }) => {
  const { title, uid, pinned } = chat;
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState("bottom");
  const navigate = useNavigate();

  const {
    editChatId,
    editTitle,
    setEditTitle,
    setEditChatId,
    setActiveChat,
    sideChat,
    setTempChatId,
  } = ChatStore();
  const [ifActive, setIfActive] = useState(false);
  const menuButtonRef = useRef(null);
  const inputRef = useRef(null);
  const { setInputFileName, setFileSize, setFileUrl, setShowUpload } =
    FileUploadStore();
  const { setPrompt } = PromptStore();
  const { setExpanded } = UIStore();

  const handleTitleChange = (event, uid) => {
    if (event.key === "Enter") {
      if (!event.target.value.trim()) {
        // Reset to original title if input is empty
        setEditTitle(title);
      } else {
        updateChatTitle(uid, event.target.value);
      }
      setEditChatId(null);
    }
  };

  const handleBlur = (uid) => {
    // Reset to original title if "Enter" wasn't pressed
    if (editChatId === uid) {
      setEditTitle(title);
      setEditChatId(null);
    }
  };

  const toggleEdit = (uid, title) => {
    setEditChatId(uid);
    setEditTitle(title);
    setActiveDropdown(null);

    // Wait for the state to update, then focus on the input
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const RemoveUpload = () => {
    setInputFileName("");
    setFileSize("");
    setFileUrl("");
    setPrompt("");
    setShowUpload(false);
    setExpanded(false);
  };

  const handleDotClick = (uid) => {
    if (!menuButtonRef.current) return;

    const rect = menuButtonRef.current.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom;
    const spaceAbove = rect.top;

    if (activeDropdown === uid) {
      setActiveDropdown(null); // Close if already open
    } else {
      setDropdownPosition(
        spaceBelow < 150 && spaceAbove > 150 ? "top" : "bottom"
      );
      setActiveDropdown(uid);
    }
  };

  const handlePinChat = (chatId) => {
    ChatStore.setState((prevState) => {
      const updatedSideChat = prevState.sideChat.map((chatItem, index) => {
        if (chatItem.uid === chatId) {
          return {
            ...chatItem,
            pinned: !chatItem.pinned,
            pinned_date: !chatItem.pinned
              ? {
                  seconds: Math.floor(Date.now() / 1000),
                  nanoseconds: (Date.now() % 1000) * 1e6,
                }
              : null,
            originalIndex: index,
          };
        }
        return chatItem;
      });

      // Separate pinned and unpinned chats
      const pinnedChats = updatedSideChat
        .filter((chat) => chat.pinned)
        .sort((a, b) => {
          const dateA =
            a.pinned_date.seconds * 1000 + a.pinned_date.nanoseconds / 1e6;
          const dateB =
            b.pinned_date.seconds * 1000 + b.pinned_date.nanoseconds / 1e6;
          return dateB - dateA; // Sorting in descending order
        });
      const unpinnedChats = updatedSideChat
        .filter((chat) => !chat.pinned)
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

      return { sideChat: [...pinnedChats, ...unpinnedChats] };
    });

    setActiveDropdown(null);

    pinChatAPI(chatId, !pinned);
  };

  useEffect(() => {
    if (menuButtonRef.current && pinned) {
      menuButtonRef.current.style.display = "flex";
    }
  }, [pinned, menuButtonRef]);

  return (
    <li className="w-full nav-item hide-scrollbar relative">
      <div
        className={`w-full flex justify-between items-center rounded-md`}
        onMouseEnter={() => {
          if (menuButtonRef.current) {
            menuButtonRef.current.style.display = "flex";
          }
        }}
        onMouseLeave={() => {
          if (menuButtonRef.current && !pinned) {
            menuButtonRef.current.style.display = "none";
          }
          setActiveDropdown(null);
        }}
      >
        {editChatId === uid ? (
          <input
            autoFocus
            ref={inputRef}
            type="text"
            className={`rounded px-2 py-1 w-full outline-none text-wrap text-sm ${
              theme === "dark"
                ? "bg-[#252530] text-white"
                : "bg-[#F5F8FD] border-[0.25px] border-[#6D808D33] text-[#OD3148]"
            }`}
            value={editTitle}
            onChange={(e) => setEditTitle(e.target.value)}
            onKeyDown={(e) => handleTitleChange(e, uid)}
            onBlur={() => handleBlur(uid)} // Reset title on blur
          />
        ) : (
          <NavLink
            to={`/chat/${uid}`}
            onClick={() => {
              useGenerateApiSignal.getState().signal?.abort();
              RemoveUpload();
              setActiveChat(uid);
              setTempChatId(null);
              setActiveDropdown(null);
            }}
            onTouchStart={(e) => {
              e.preventDefault();
              useGenerateApiSignal.getState().signal?.abort();
              navigate(`/chat/${uid}`);
              RemoveUpload();
              setActiveChat(uid);
              setActiveDropdown(null);
            }}
            className={({ isActive }) => {
              setIfActive(isActive);
              return `w-full text-sm flex items-center flex-1 px-4 py-2 h-[38px] rounded-md no-underline ${
                isActive
                  ? theme === "dark"
                    ? "text-[#E8EEFF] bg-[#3A3E4C]"
                    : "text-[#OD3148] bg-[#FEEAE6]"
                  : theme === "dark"
                  ? "hover:bg-[#3A3E4C]"
                  : " hover:bg-[#FEEAE6]"
              }`;
            }}
          >
            <span className="w-[95%] truncate">{
            title?.length
              ? `${title.substring(0, 150)}`
              : ""
            }</span>
          </NavLink>
        )}

        {editChatId !== uid && (
          <div className="menu-container flex items-center absolute right-1">
            <ChatTitleDotMenu
              menuButtonRef={menuButtonRef}
              chat={chat}
              handleDotClick={handleDotClick}
              theme={theme}
            />
            {activeDropdown === uid && (
              <div
                className={`absolute right-1.5 ${
                  dropdownPosition === "top"
                    ? "bottom-full mb-1"
                    : "top-full mt-1"
                } bg-white dark:bg-[#40404D] dark:text-white rounded-lg shadow-lg py-1 min-w-[120px] z-50`}
              >
                <button
                  onClick={() => handlePinChat(uid, sideChat)}
                  className="w-full flex items-center px-3 py-2 text-sm text-gray-700 dark:text-white border-b dark:border-[#66666666] border-[#CCCCCC]"
                >
                  {pinned ? (
                    <>
                      <LuPinOff className="w-[17px] h-[17px] mr-2 text-[#0D3148] dark:text-white" />
                      Unpin
                    </>
                  ) : (
                    <>
                      <MdOutlinePushPin className="w-[17px] h-[17px] mr-2 text-[#0D3148] dark:text-white" />
                      Pin
                    </>
                  )}
                </button>
                <button
                  onClick={() => toggleEdit(uid, title)}
                  className={`w-full flex items-center px-3 py-2 text-sm text-gray-700 dark:text-white ${
                    ifActive
                      ? ""
                      : "border-b dark:border-[#66666666] border-[#CCCCCC]"
                  }`}
                >
                  <EditIcon className="w-4 h-4 mr-2 dark:fill-white fill-[#0D3148]" />
                  Edit
                </button>
                {!ifActive && (
                  <button
                    onClick={() => deleteChat(uid, sideChat)}
                    className="w-full flex items-center px-3 py-2 text-sm text-gray-700 dark:text-white"
                  >
                    <RiDeleteBin6Line className="w-4 h-4 mr-2 text-[#0D3148] dark:text-white" />
                    Delete
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </li>
  );
};

export const SidebarChatList = ({ theme }) => {
  const { sideChat } = ChatStore();

  return (
    // <div className="overflow-y-scroll side-chat-scroll max-h-[calc(100vh-280px)] min-h-[calc(100vh-180px)]">
    <div className="overflow-y-scroll flex-grow">
      {/* Recent Chats Section */}
      <div className="mt-2 text-left">
        <span
          className={`text-sm font-semibold opacity-100 ${
            theme === "dark" ? "text-white" : "text-[#6D808D]"
          }`}
        >
          Recent
        </span>
      </div>
      <ul className="flex flex-col space-y-2 mt-1">
        {sideChat &&
          sideChat.map((chat, index) => (
            <ChatTitle key={index} chat={chat} theme={theme} />
          ))}

        <li className="my-20 h-10"></li>
      </ul>
    </div>
  );
};
