import React, { useState, useRef, useEffect } from "react";
import { FiLogOut } from "react-icons/fi";
import { useTheme } from "../../context/ThemeProvider";
import UserAuthStore from "../../store/userStore";
import { logout } from "../../api/logout";

const UserDropdown = () => {
  const { user } = UserAuthStore();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { theme, setTheme } = useTheme();

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleLogout = () => {
    logout(setTheme);
    setIsOpen(false);
  };

  const menuItems = [
    {
      icon: FiLogOut,
      label: "Logout",
      onClick: handleLogout,
    },
  ];

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`flex items-center justify-center w-10 h-10 rounded-full ${
          theme === "dark" ? "bg-[#2D3748] hover:bg-[#3A3E4C] border-[#707070] border-[0.5px]" : "bg-gray-200 hover:bg-gray-300 border-[#CCCCCC] border-[0.5px]"
        } transition-colors duration-200 cursor-pointer`}
      >
        <img
          height="40px"
          width="40px"
          src={user?.photoURL ? user.photoURL : "/default-user.png"} 
          alt="User"
          id="user_img"
          style={{objectFit: "cover"}}
          className="rounded-full"
        />
      </button>

      {isOpen && (
        <div
          className={`absolute right-0 mt-3 w-32 rounded-lg border z-30 ${
            theme === "dark" ? "bg-[#424453] border-[#666666]" : "bg-white border-gray-300"
          }`}
        >
          <div className="m-1 rounded-lg">
            {menuItems.map((item, index) => (
              <button
                key={index}
                onClick={item.onClick}
                className={`w-full flex items-center px-4 py-2 text-sm ${
                  theme === "dark"
                    ? "text-gray-300 hover:bg-[#444858]"
                    : "text-gray-700 hover:bg-gray-100"
                } ${
                  index < menuItems.length - 1 ? "border-b border-gray-300" : ""
                }`}
              >
                <item.icon
                  className="w-4 h-4 mr-3"
                  color={theme === "dark" ? "#37E8C5" : "#FF5B29"}
                />
                {item.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
