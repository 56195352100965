import React, { useEffect, useRef, useState } from "react";
import {
  ArrowUp,
  BlackCross,
  EditIcon,
  User,
  WhiteCross,
} from "../../../assets";
import RegenerateDropdown from "../../Dropdowns/RegenerateDropdown";
import IconPhotonDark from "../../../assets/images/Photon_icon_small.png";
import IconPhotonLight from "../../../assets/images/photon_light_icon_small.png";
import CopyButton from "../../Button/CopyButton";
import { FileStore } from "../../../store/fileUploadStore";
import MarkdownRenderer from "../../Markdown/MarkdownRenderer";

const Actions = ({
  regenFunc,
  chatId,
  handlePrevious,
  handleNext,
  disablePrevious,
  disableNext,
  currentIndex,
  totalAnswers,
  showLoadingAnswer,
  content,
  files,
  regenerateIndex,
  setRegenerateIndex,
  index,
}) => (
  <div className="flex justify-between items-center w-full gap-2 sm:gap-0 my-2 mt-2">
    <div className="cursor-pointer h-[32px]">
      <CopyButton text={content} />
    </div>

    <div className="flex justify-end gap-1.5 items-center w-full h-full sm:w-auto text-xs">
      {totalAnswers > 1 && (
        <div className="flex items-center gap-1.5 sm:gap-2">
          <button
            className={`dark:text-white p-0.5 sm:p-0 ${
              disablePrevious
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer hover:text-gray-600 dark:hover:text-gray-300"
            }`}
            onClick={handlePrevious}
            disabled={disablePrevious}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>

          <span className="dark:text-white font-medium text-xs">
            {currentIndex + 1}/{totalAnswers}
          </span>

          <button
            className={`dark:text-white p-0.5 sm:p-0 ${
              disableNext
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer hover:text-gray-600 dark:hover:text-gray-300"
            }`}
            onClick={handleNext}
            disabled={disableNext}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        </div>
      )}
      {totalAnswers > 0 && (
        <div>
          <RegenerateDropdown
            chatId={chatId}
            onRegenerate={regenFunc}
            showLoadingAnswer={showLoadingAnswer}
            files={files}
            regenerateIndex={regenerateIndex}
            setRegenerateIndex={setRegenerateIndex}
            index={index}
          />
        </div>
      )}
    </div>
  </div>
);

const OneMessage = ({
  index,
  chat,
  user,
  theme,
  regenFunc,
  regenerateIndex,
  setRegenerateIndex,
  editedContent,
  setEditedContent,
  editFunctionality,
  generateStatus,
  setGenerateStatus,
}) => {
  const messageRef = useRef(null);
  const textareaRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(chat?.currentIndex ?? 0);
  const currentAnswer = chat.answers[currentIndex];
  const [showLoadingAnswer, setShowLoadingAnswer] = useState(false); //is message loading
  const [answerStarted, setAnswerStarted] = useState(false);
  const { files } = FileStore();
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [originalContent, setOriginalContent] = useState(chat.content); // Store the original prompt

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "24px";
      const newHeight = Math.min(textarea.scrollHeight, 5 * 20);
      textarea.style.height = `${Math.max(24, newHeight)}px`;
    }
  };

  useEffect(() => {
    setCurrentIndex(chat?.currentIndex ?? 0);
  }, [chat?.currentIndex]);

  useEffect(() => {
    // Set answerStarted to true only when actual content starts arriving
    if (currentAnswer?.content && currentAnswer.content.trim() !== "") {
      setAnswerStarted(true);
    }
  }, [currentAnswer?.content]);

  useEffect(() => {
    const chatArea = document.getElementById("chat-messages");
    if (chatArea && messageRef.current) {
      const isAtBottom =
        chatArea.scrollHeight - chatArea.scrollTop === chatArea.clientHeight;
      if (isAtBottom) {
        messageRef.current.style.marginBottom = "8px";
      } else {
        messageRef.current.style.marginBottom = "0";
      }
    }
  }, [chat]);

  const handleRegenerate = async (
    chatId,
    modelSelected,
    globalSearchDropdown
  ) => {
    setGenerateStatus(true);
    try {
      await regenFunc(chatId, modelSelected, globalSearchDropdown);
    } catch (error) {
      console.error("Regeneration failed:", error);
    } finally {
      setGenerateStatus(false);
    }
  };

  const handleNext = () => {
    if (currentIndex < chat.answers.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  // for redirecting the user to the end of the specific answer when the user clicks on the next or previous button.
  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [currentIndex]);

  const handleEdit = () => {
    setIsEditing(true);
    setOriginalContent(chat.content);
    setEditedContent(chat.content);

    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(
          textareaRef.current.value.length,
          textareaRef.current.value.length
        );
        textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
      }
    }, 0);
  };

  const handleSave = () => {
    if (editedContent.trim() === "") {
      // If the edited content is empty, revert to the original content
      setEditedContent(originalContent);
      setIsEditing(false);
      return;
    }

    // If content is valid, update and call API
    setIsEditing(false);
    editFunctionality(index, editedContent);
  };

  const handleCancel = () => {
    setEditedContent(originalContent); // Restore original message
    setIsEditing(false); // Close edit mode without making API call
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [editedContent]);

  // Detect click outside textarea and cancel editing
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (messageRef.current && !messageRef.current.contains(event.target)) {
        if (event.target.closest(".save-button")) {
          return; // Prevent canceling if clicking save
        }
        handleCancel();
      }
    };

    if (isEditing) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing]);

  return (
    <div
      className="mt-1.5 w-full"
      ref={messageRef}
      key={chat?.content?.length}
    >
      <div
        className={`rounded-full bg-gray-200 flex items-center justify-center border-b-2 border-gray-300 dark:border-gray-700 mt-2 ${
          theme === "dark"
            ? "border-[#707070] border-[0.5px]"
            : "border-[#CCCCCC] border-[0.5px]"
        }`}
        style={{ height: "32px", width: "32px" }}
      >
        <img
          src={user.photoURL ? user.photoURL : User}
          alt="User Icon"
          className="rounded-full"
          style={{ width: "35px", objectFit: "cover" }}
        />
      </div>
      <div className="flex justify-start items-center w-full gap-4 my-2" 
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {isEditing ? (
          <textarea
            autoFocus
            ref={textareaRef}
            value={editedContent}
            onChange={(e) => {
              setEditedContent(e.target.value);
              adjustTextareaHeight(); // Adjust height on change
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // Prevents adding a new line
                handleSave();
              } else if (e.key === "Escape") {
                handleCancel();
              }
            }}
            className={`border-[0.25px] text-[15px] p-2 w-full rounded-lg outline-none resize-none ${
              theme === "dark"
                ? "bg-[#252530] text-white border-[#454E5A]"
                : "bg-[#F5F8FD] text-black border-[#6D808D33]"
            }`}
          />
        ) : (
          <div className="message rounded-lg dark:bg-[#30303D] dark:text-[#E8EEFF] opacity-100 bg-white text-[#0D3148] px-2 sm:px-4 py-1 inline-block max-w-fit w-[93%] break-words overflow-x-auto">
            <div className="whitespace-pre-wrap my-1.5 leading-6 sm:leading-7 font-sans text-[15px]">
              {chat.content}
            </div>
          </div>
        )}

        <div className="flex items-center gap-2">
          {isEditing ? (
            <>
              <button
                className={`bg-[#FAAD47] opacity-100 rounded-full w-8 h-8 flex items-center justify-center ${
                  generateStatus ? "cursor-not-allowed opacity-50" : ""
                }`}
                onMouseDown={handleSave}
                disabled={generateStatus}
              >
                <ArrowUp className="text-white rotate-180 w-4 h-4" />
              </button>
              <button
                className="dark:bg-[#A8B2D1] bg-white dark:bg-opacity-25 flex items-center justify-center rounded-full p-2.5 w-8 h-8"
                onClick={handleCancel}
              >
                {theme === "dark" ? <WhiteCross /> : <BlackCross />}
              </button>
            </>
          ) : (
            isHovering && (
              <button
                className={`dark:bg-[#A8B2D1] bg-white dark:bg-opacity-25 flex items-center justify-center rounded-full p-2 w-8 h-8 ${
                  generateStatus
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-200 dark:hover:bg-gray-700"
                }`}
                onClick={!generateStatus ? handleEdit : undefined} // Prevent click when loading
                disabled={generateStatus}
                aria-disabled={generateStatus}
              >
                <EditIcon className="w-4 h-4 dark:fill-white" />
              </button>
            )
          )}
        </div>
      </div>

      <div
        className={`rounded-full dark:bg-[#30303D] bg-white flex items-center justify-center relative mt-3 ${
          theme === "dark"
            ? "border-[#707070] border-[0.5px]"
            : "border-[#CCCCCC] border-[0.5px]"
        }`}
        style={{ height: "32px", width: "32px" }}
      >
        {regenerateIndex === index ? (
          <div
            className="w-4 h-4 border-2 border-t-2 dark:border-t-[#61FCD9] border-t-[#FF5B29] rounded-full animate-spin"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <img
            src={theme === "dark" ? IconPhotonDark : IconPhotonLight}
            alt="Bot Icon"
            className="w-4 object-cover"
          />
        )}
      </div>

      {/* Bot message section - only shown when answer has started */}
      {answerStarted && currentAnswer?.content && (
        <>
          <div
            key={index}
            className="message rounded-lg dark:bg-[#30303D] dark:text-[#E8EEFF] bg-white text-[#0D3148] px-2 sm:px-4 py-1 my-2 w-full break-words overflow-x-auto"
          >
            <MarkdownRenderer content={currentAnswer?.content} />
            {!(regenerateIndex === index) && (
              <Actions
                regenFunc={handleRegenerate}
                chatId={chat.id}
                handlePrevious={handlePrevious}
                handleNext={handleNext}
                disablePrevious={currentIndex === 0}
                disableNext={currentIndex === chat.answers.length - 1}
                currentIndex={currentIndex}
                totalAnswers={chat.answers.length}
                showLoadingAnswer={showLoadingAnswer}
                content={currentAnswer?.content}
                files={files}
                index={index}
                regenerateIndex={regenerateIndex}
                setRegenerateIndex={setRegenerateIndex}
              />
            )}
          </div>
          <div className="border-b border-gray-300 dark:border-gray-700"></div>
        </>
      )}
    </div>
  );
};

export default OneMessage;
