import { FileStore } from "../store/fileUploadStore";

export const useDragAndDrop = () => {
    const { isDragging, setIsDragging } = FileStore();
  
    const handleDrag = (e) => {
      // e.preventDefault();
      // e.stopPropagation();
    };
  
    const handleDragIn = (e) => {
      // e.preventDefault();
      // e.stopPropagation();
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        // setIsDragging(true);
      }
    };
  
    const handleDragOut = (e) => {
      // e.preventDefault();
      // e.stopPropagation();
      // setIsDragging(false);
    };
  
    const handleDrop = (e, fileInputRef, handleFileUpload, chat_id, setFiles, files, user, orgId) => {
      e.preventDefault();
      // e.stopPropagation();
      setIsDragging(false);
  
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        if (fileInputRef.current) {
          const dt = new DataTransfer();
          Array.from(e.dataTransfer.files).forEach((file) => dt.items.add(file));
          fileInputRef.current.files = dt.files;
  
          const event = new Event("change", { bubbles: true });
          fileInputRef.current.dispatchEvent(event);
  
          handleFileUpload(
            e,
            { target: fileInputRef.current },
            chat_id,
            fileInputRef,
            setFiles,
            files,
            user,
            orgId
          );
        }
      }
    };
  
    return {
      isDragging,
      handleDrag,
      handleDragIn,
      handleDragOut,
      handleDrop,
    };
  };
  